import { ContentfulBonus } from "../../types/graphql-types"

import Casino from "~/models/casino"

export default class Bonus {
  id: string
  tagline: string
  type: string
  value: string
  matchShare: string
  wageringRequirement: string
  period: string
  casino: Casino

  constructor(bonus: ContentfulBonus) {
    this.tagline = bonus.tagline || ""
    this.id = bonus.id
    this.type = bonus.type || ""
    this.value = bonus.value || ""
    this.matchShare = bonus.matchShare || ""
    this.wageringRequirement = bonus.wageringRequirements || ""
    this.period = bonus.period || ""
    this.casino = new Casino(bonus.casino![0]!)
  }
}

import React from "react"
import cx from "classnames"

import Casino from "~/models/casino"

type Props = {
  className?: string
  children: string | JSX.Element
  type: "primary" | "secondary"
  casino?: Casino
}

function LinkButton({ children, className, type, casino }: Props) {
  return (
    <a
      href={casino ? `/links/${casino.slug}/` : ""}
      target="_blank"
      rel="noopener nofollow"
      className={cx(
        "border-2 font-bold block py-3 px-9 leading-none text-center text-lg rounded-lg",
        {
          "bg-blue-600 border-blue-600": type == "primary",
          "text-white": type == "primary",
          "hover:bg-blue-700 hover:border-blue-700": type == "primary",
        },
        {
          "border-blue-600": type == "secondary",
          "bg-white": type == "secondary",
          "text-blue-600": type == "secondary",
          "hover:bg-blue-600": type == "secondary",
          "hover:text-white": type == "secondary",
        },
        className
      )}
    >
      {children}
    </a>
  )
}

export default LinkButton

import { ContentfulCasino, ContentfulAsset } from "../../types/graphql-types"

import Bonus from "~/models/bonus"
import Page from "~/models/page"

export default class Casino {
  id: string
  slug: string
  logo: ContentfulAsset
  name: string
  description: string
  rating: number
  numberOfGames: string
  payoutPercentage: string
  payoutPeriod: string
  pros: string[]
  cons: string[]
  gameProviders: string[]
  paymentMethods: string[]
  foundationYear: number
  operatingSystems: string[]
  availableGames: string[]
  bonuses: Bonus[]
  page?: Page

  constructor(casino: ContentfulCasino) {
    this.id = casino.id
    this.slug = casino.slug || ""
    this.logo =
      casino.logo?.localFile?.childImageSharp?.gatsbyImageData ||
      casino.logo?.gatsbyImageData
    this.name = casino.name || ""
    this.description = casino.description?.description! || ""
    this.rating = casino.rating || 0
    this.numberOfGames = casino.numberOfGames || ""
    this.payoutPercentage = casino.payoutPercentage || ""
    this.payoutPeriod = casino.payoutPeriod || ""
    this.pros = casino.pros?.map(pro => pro!) || []
    this.cons = casino.cons?.map(con => con!) || []
    this.gameProviders =
      casino.gameProviders?.map(gameProvider => gameProvider!) || []
    this.paymentMethods = casino.paymentMethods?.map(method => method!) || []
    this.operatingSystems = casino.operatingSystems?.map(os => os!) || []
    this.availableGames = casino.availableGames?.map(game => game!) || []
    this.foundationYear = casino.foundationYear || 0
    this.bonuses = casino.bonuses
      ? casino.bonuses!.map((bonus: any) => new Bonus(bonus))
      : []

    if (casino.page) {
      this.page = new Page(casino.page!)
    }
  }
}

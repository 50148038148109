import React from "react"
import cx from "classnames"

import StarIcon from "~/ui/identity/icon/star"

type Props = {
  className?: string
  rating: number
  color?: string
}

Stars.defaultProps = {}

function Star({ index, rating, color }: any) {
  let v = Math.round(rating * 2) / 2

  let style = "fill"

  if (index + 0.5 <= v && index + 1 > v) {
    style = "half"
  }

  if (index + 0.5 > v) {
    style = "outline"
  }

  return (
    <StarIcon
      width={20}
      className="mr-1 last:mr-0"
      style={style}
      color={color}
    />
  )
}

function Stars({ className, rating, color }: Props) {
  return (
    <div className={cx("flex", className)}>
      {Array.from({ length: 5 }).map((_: any, index: number) => {
        return <Star key={index} index={index} rating={rating} color={color} />
      })}
    </div>
  )
}

export default Stars
